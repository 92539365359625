import Vue from "vue";
import Vuetify from "vuetify";
import 'vuetify/dist/vuetify.min.css'
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import fa from 'vuetify/src/locale/fa.ts'
import en from 'vuetify/src/locale/en.ts'
Vuetify.config.silent = true

Vue.use(Vuetify);


const opts = {
  rtl: true,
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#20B1EA", // #FFCDD2
        secondary: "#2A3465", // #E53935
        accent: "#FF5252", // #3F51B5
        primary_lighten_bg: "#f0f7ff",
        text_dark_383456: "#383456",
        green_bg: "#25AC71",
        appbar_color: "#ffffff",
        background: "#ffffff",
        icon_color: "#232126",
      },
      dark: {
        primary: "#20B1EA", // #FFCDD2
        secondary: "#2A3465", // #E53935
        accent: "#FF5252", // #3F51B5
        primary_lighten_bg: "#f0f7ff",
        text_dark_383456: "#383456",
        green_bg: "#25AC71",
        appbar_color: "#232126",        
        background: "#232126",
        icon_color: "#ffffff",
      },
    },
  },
  lang: {
    locales: { fa, en },
    current: 'fa',
  },
};

export default new Vuetify(opts);
