/* eslint-disable no-unused-vars */
import axios from "@/http/httpApplication.js";

const actions = {
  loginUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios({
        url: `${process.env.VUE_APP_API_URL}/auth/user-otp`,
        data: params,
        method: "POST",
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.clear();
          window.localStorage.clear();
          reject(err);
        });
    });
  },
  loginDoctor({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios({
        url: `${process.env.VUE_APP_API_URL}/auth/doctor-otp`,
        data: params,
        method: "POST",
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.clear();
          window.localStorage.clear();
          reject(err);
        });
    });
  },
  Verification({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios({
        url: `${process.env.VUE_APP_API_URL}/auth/verify-otp`,
        data: params,
        method: "POST",
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.authenticated == 1) {
              const token = "Bearer " + response.data.token;
              axios.defaults.headers.common["Authorization"] = token;
              this.state.authenticated = true;
              commit("auth_success", token);
              resolve(response);
            } else if (response.data.authenticated == 0) {
              const token = "Bearer " + response.data.token;
              axios.defaults.headers.common["Authorization"] = token;
              this.state.authenticated = false;
              commit("auth_success", token);
              resolve(response);
            } else {
              commit("auth_error");
              reject("something went wrong");
            }
          } else {
            commit("auth_error");
            reject("something went wrong");
          }
        })
        .catch((err) => {
          commit("auth_error");
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("logout");
      localStorage.clear();
      window.localStorage.clear();

      delete axios.defaults.headers.common["Authorization"];
      resolve();
    });
  },

  AssignChecker({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_API_URL}/exam-assign/check-is-assign?examId=${params}&username=${this.state.mobileNumber}`,
        method: "get",
        headers: {
          Authorization: this.state.token,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  
  AssignMe() {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_API_URL}/exam-assign/me`,
        method: "post",
        data: {
          userUsername: this.state.mobileNumber,
          examId: "23",
        },
        headers: {
          Authorization: this.state.token,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // fakeLogin({ commit }) {
  //   var token = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhZG1pbiIsInJvbGUiOiJST0xFX0FETUlOIiwiaXNzIjoibWluZG9sb2d5IiwiaXNBZG1pbiI6dHJ1ZSwiZXhwIjoxNjY2MjY5NjU0LCJpYXQiOjE2NDg5ODk2NTR9.qcumuuDoRpQA8wHGwfK3GjWEWqkgjQgfJh98FMVc7iU";
  //   axios.defaults.headers.common["Authorization"] = token;
  //   this.state.authenticated = true;
  //   commit("auth_success", token);
  // }
}

export default actions
