var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("PWAPrompt"),
      _c("notifications", { attrs: { position: "bottom right" } }),
      _c("router-view"),
      _vm.isLoading
        ? _c("div", { staticClass: "loading" }, [
            _c("div", { staticClass: "block" }, [
              _c("section", [_c("span", { staticClass: "loader" })]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }