<!-- InstallPrompt.vue -->
<template>
  <v-banner v-if="isInstallPromptAvailable" dark app color="primary" outlined rounded sticky :class="bannerClasses">
    {{ $t("Get our free app. It won't take up space on your phone!") }}

    <template v-slot:actions>
      <v-btn color="secondary" @click="dismissPrompt">{{ $t('Dismiss') }}</v-btn>
      <v-btn color="secondary" @click="installPWA">{{ $t('Install') }}</v-btn>
    </template>
  </v-banner>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data: () => ({
    installEvent: undefined,
    shown: false,
  }),

  computed: {
    isInstallPromptAvailable() {
      return this.installEvent && this.shown;
    },
    bannerClasses() {
      return {
        f14: true,
        mx4: true,
        primary: true,
      };
    },
  },

  beforeMount() {
    window.addEventListener('beforeinstallprompt', this.handleInstallPrompt);
  },

  beforeDestroy() {
    window.removeEventListener('beforeinstallprompt', this.handleInstallPrompt);
  },

  methods: {
    handleInstallPrompt(e) {
      e.preventDefault();
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.installEvent = e;
        this.shown = true;
      }
    },

    installPWA() {
      if (this.isInstallPromptAvailable) {
        this.installEvent.prompt();
        this.installEvent.userChoice.then((choice) => {
          this.dismissPrompt();
          if (choice.outcome === 'accepted') {
            // Do something additional if the user chose to install
            if (Notification.permission === "granted") {
              console.log("Notification.permission has been granted");
            } else if (Notification.permission !== "denied") {
              Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {     
                }
              });
            }
          } else {
            Cookies.set("add-to-home-screen", null, { expires: 15 });
            this.installEvent = undefined;
          }
        });
      }
    },

    dismissPrompt() {
      this.shown = false;
    },
  },
};
</script>

<style scoped>
/* Add scoped styles if necessary */
</style>
