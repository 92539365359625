const mutations = {
    updateLoadingBar(state, status) {
        state.loadingBar = status
    },

    auth_request(state) {
        state.status = "loading";
    },
    auth_success(state, token) {
        state.status = "success";
        state.token = token;
    },
    auth_error(state) {
        state.status = "error";
        state.token = "";
    },
    logout(state) {
        state.status = "";
        state.token = "";
    },

    httpLoading(state, isLoading) {
        if (isLoading) {
            state.refCount++;
            state.isLoading = true;
        } else if (state.refCount > 0) {
            state.refCount--;
            state.isLoading = state.refCount > 0;
        } else {
            state.refCount = 0;
            state.isLoading = false;
        }
    },

    update_authenticated(state, val) {
        state.authenticated = val;
    },
    update_firebaseToken(state, val) {
        state.firebaseToken = val;
    },
    update_mobileNumber(state, val) {
        state.mobileNumber = val;
    },
    update_clientType(state, val) {
        state.clientType = val;
    },
    update_doctorId(state, val) {
        state.doctorId = val;
    },
    update_profileFilled(state, val) {
        state.profileFilled = val;
    },
    update_clinicId(state, val) {
        state.clinicId = val;
    },

    update_locale(state, val) {
        state.locale = val;
    },

    update_notificationActive(state, val) {
        state.notificationActive = val
    },
    update_threeDotsActive(state, val) {
        state.threeDotsActive = val;
    },
    update_threeDotsMenu(state, val) {
        state.threeDotsMenu = val;
    },
    update_helloActive(state, val) {
        state.helloActive = val;
    },
    update_backActive(state, val) {
        state.backActive = val;
    },
    update_toolbarTitleActive(state, val) {
        state.toolbarTitleActive = val;
    },
    update_toolbarTitle(state, val) {
        state.toolbarTitle = val;
    },
    update_fullName(state, val) {
        state.fullName = val;
    },
    update_notificationCount(state, val) {
        state.notificationCount = val;
    },
    // user
    update_clientId(state, val) {
        state.clientId = val;
    },
    update_payment_amount(state, val) {
        state.paymentAmount = val;
    },
    update_payment_status(state, val) {
        state.paymentStatus = val;
    }
};

export default mutations;
