// en, de, fr, pt
export default {
  fa: {

    //---------------------------------------------------------------------------------------------------------------------
    // Auth
    //---------------------------------------------------------------------------------------------------------------------
    'It`s time to get to yourself!': "وقتشه به خودت برسی!",
    'In Mindology, the comprehensive platform of psychology': " در مایندولوژی، پلتفرم جامع روانشناسی",
    'Train and practice regularly to improve your quality of life': "آموزش ببین و برای بهبود کیفیت زندگیت منظم تمرین کن",
    'Login or registration means accept': "ورود یا ثبت نام به معنای قبول",
    'terms and conditions': "قوانین و مقررات",
    'and': "و",
    'Version': "نسخه",
    'privacy policy': "سیاست های حریم خصوصی",
    'of Mindology.': "مایندولوژی است.",
    'Client login': "ورود کاربر",
    'Specialist login': "ورود متخصص",
    'Terms and Conditions': "قوانین و مقررات",
    'You have not accepted the rules and regulations of Mindology': "شما قوانین و مقررات مایندولوژی را نپذیرفته اید",

    //---------------------------------------------------------------------------------------------------------------------
    // Login
    //---------------------------------------------------------------------------------------------------------------------
    'Login to': "به حساب",
    'your account': "کاربری خود وارد شوید",
    'clint account': "کاربر گرامی وارد شوید",
    'doctor account': "متخصص گرامی وارد شوید",
    'The verification code will be sent to your mobile number': "کد تایید به شماره موبایل شما ارسال خواهد شد",
    'Send code': "ارسال کد",
    'Confirm': "تایید",
    'Enter country code is required': "وارد کردن کد کشور الزامی است",
    'Country code must start with +': "کد کشور باید با + شروع شود",
    'Enter the mobile number': "شماره همراه را وارد کنید",
    'Mobile number must be at least 10 characters': "شماره همراه باید حداقل 10 کاراکتر باشد",
    'Mobile number must be at less than 11 characters': "شماره همراه باید حداکثر 11 کاراکتر باشد",
    'Please enter your mobile number': "لطفا شماره همراه خود را وارد نمائید",
    'Mobile number': "شماره همراه",
    'No Zero': "بدون صفر",

    //---------------------------------------------------------------------------------------------------------------------
    // Verification Code
    //---------------------------------------------------------------------------------------------------------------------
    'Mobile number verification': "تأیید شماره موبایل",
    'A code has been sent to': "یک کد به شماره",
    'Please enter it in the box below.': "ارسال شده است. لطفا آن را در باکس زیر وارد کنید.",
    'Wrong number?': "شماره اشتباه است؟",
    'Resend': "ارسال مجدد",
    'Error receiving information': "خطا در دریافت اطلاعات",
    'Error': "خطا در دریافت اطلاعات",

    //---------------------------------------------------------------------------------------------------------------------
    // Register
    //---------------------------------------------------------------------------------------------------------------------
    'Complete your profile': "پروفایل خود را تکمیل کنید",
    'Please enter your information correctly in the field below': "لطفا اطلاعات خود را به صورت درست در قسمت زیر وارد کنید",
    'Enter your name': "نام",
    'Enter your username': "نام کاربری خود را به انگلیسی وارد نمائید",
    'Enter your last name': "نام خانوادگی",
    'Enter your age': "سن",
    'Enter your bio': "بیوگرافی",
    'Choose your gender': "جنسیت",
    'No data': "اطلاعاتی یافت نشد",
    'Choose your city': "محل سکونت",

    'Educational qualifications': "مدرک تحصیلی",
    'Work specialties': "تخصص های کاری",
    'Field of activity': "حوزه فعالیت",

    'Man': "مرد",
    'Woman': "زن",
    'Other': "سایر",
    'Other cases': "سایر موارد",

    '': "",

    //---------------------------------------------------------------------------------------------------------------------
    // GLOBAL
    //---------------------------------------------------------------------------------------------------------------------
    'Client' : "کاربر",
    'Specialist' : "متخصص",
    'specialist' : "متخصص",

    'OK' : "باشه",
    'Explore' : "اکسپلور",
    'Search' : "جستجو",
    'Mood status' : "وضعیت روحی",
    'Profile' : "پروفایل",
    'My page' : "صفحه من",
    'Search...' : "جستجو...",
    'Home' : "صفحه اصلی",
    'Setting' : "تنظیمات",
    'Log out' : "خروج از حساب کاربری",

    'Do you want to log out?' : "آیا میخواهید از حساب کاربری خود خارج شوید؟",
    'Yes' : "بله",
    'No' : "خیر",

    'Hello!' : "سلام!",
    'Week' : "هفته",
    'Month' : "ماه",
    'Count: ' : "تعداد: ",
    'Signup' : "عضویت",

    'Notifications' : "اعلان ها",
    
    'The maximum length is 100 characters' : "حداکثر 100 کاراکتر مجاز می باشد",
    'The maximum length is 128 characters' : "حداکثر 128 کاراکتر مجاز می باشد",
    'The maximum length is 255 characters' : "حداکثر 255 کاراکتر مجاز می باشد",
    'The maximum length is 500 characters' : "حداکثر 500 کاراکتر مجاز می باشد",
    'The maximum length is 600 characters' : "حداکثر 600 کاراکتر مجاز می باشد",
    'The maximum length is 1000 characters' : "حداکثر 1000 کاراکتر مجاز می باشد",
    'The minimum length is 4 characters' : "باید حداقل 4 کاراکتر وارد نمایید ",
    'No spaces are allowed' : "فاصله مجاز نیست",
    'Only English letters and numbers are allowed' : "تنها حروف انگلیسی و اعداد مجاز است",
    'Could not upload the file!' : "فایل آپلود نشد",

    'Loading...' : "در حال بارگیری",
    'No more items' : "نتیجه ای وجود ندارد",
    'No results found' : "نتیجه ای پیدا نشد",
    'No Appointments found' : "ملاقاتی ثبت نشده است",

    'Install' : "نصب",
    'Dismiss' : "رد",
    "Get our free app. It won't take up space on your phone!" : "برنامه ما را رایگان دریافت کنید. فضای گوشی شما را اشغال نخواهد کرد",
    'Register' : "ثبت نام",
    'Please write your comment' : "لطفا نظر خود را بنویسید",
    'This section is temporarily inactive' : "این بخش موقتا غیر فعال می باشد",
    'Canceled' : "انصراف",

    //---------------------------------------------------------------------------------------------------------------------
    // User
    //---------------------------------------------------------------------------------------------------------------------
    'Activity chart': "نمودار فعالیت",
    'selected user date':'نوبت های روز انتخاب شده',
    'Follow': "دنبال کردن",
    'Followed':"دنبال شده",
    'Question': "سوال",
    'Answer': "پاسخ",
    'All': "همه",
    'Show all': "مشاهده همه",
    'Activity status': " نمودار فعالیت",
    'Exams': "آزمون ها",
    'Training': "تمرین ها",
    'Latest specialists': "آخرین متخصصین",
    'All specialists': "همه متخصصین",
    'Frequently questions': "پرسش های شما",
    'Last posts': "آخرین مطالب",
    'Last podcasts': "آخرین پادکست ها",
    'Submit': "ثبت",
    'Submit Exam': "ارسال آزمون",
    'Cancel': "بیخیال",
    'Contact': "تماس",
    'In critical situations such as any child abuse, spouse abuse or thoughts Suicide without patience Call 123(iran) And 911 (USA) Suicide Emergency': "      در مواقع بحرانی مانند هرگونه کودک آزاری ، همسر آزاری و یا افکار  خودکشی بدون صبر با شماره 123 اورژانس خودکشی تماس بگیرید",
    'Report' : "گزارش تخلف",
    'Report successfully submitted' : "گزارش تخلف با موفقیت ثبت شد",
    'Please write the reason for registration or violation in the box below and register. Your report will be reviewed by our experts':"لطفا دلیل ثبت یا تخلف را در کادر زیر بنویسید و ثبت کنید.گزارش شما توسط کارشناسان ما رسیدگی خواهد شد",

    'Add your first specialized exam': "اولین آزمون تخصصی خود را اضافه کنید",
    'Create your own custom exercise': "تمرین اختصاصی خود را بسازید",

    // Answer Questions
    'Count of your questions': "تعداد سوالات شما",
    'Add question': "سوال جدید",
    'Not answered': "پاسخ داده نشده",
    'Answered': "پاسخ داده شده",
    'View result': "مشاهده نتیجه",
    'Write your question...': '...پرسش خود را بنویسید',
    'Your question has been sent successfully and you will be notified after confirmation and response. Also, view your question through the question and answer section in the profile menu': 'سوال شما با موفقیت ارسال شد و پس از تایید و دریافت پاسخ به شما اطلاع داده خواهد شد. همچنین از طریق بخش پرسش و پاسخ در منو پروفایل سوال خود را مشاهده کنید',
    'Your question will be displayed in the list of questions after confirmation without mentioning your name or any identity and is ready to be answered by experts.': "سوال شما پس از تایید بدون ذکر نام یا هر گونه هویتی در لیست سوالات نمایش داده شده و آماده پاسخ دهی توسط متخصصین است.",
    'A moment ago': "لحظاتی قبل",
    'Days ago': "روز قبل",
    'others': "نفر دیگر",
    'And': "و",
    'comment': "یک نظر ثبت شده است",
    'comments': "نظر ثبت شده است",
    'Submit comment': "ثبت نظر",
    'Thank you! Your comment has been successfully submitted': "با تشکر ! نظر شما با موفقیت ثبت شد",
    'Comment successfully deleted' : "دیدگاه شما با موفقیت حذف شد",
    'Are you sure ?':"آیا مطمعن هستید ؟",
    'from':"از",
    'Start':"شروع",
    'Write...':"بنویسید...",
    'Please enter the answer':"لطفا پاسخ را وارد نمائید",
    'Please enter a maximum of 256 characters':"لطفا حداکثر 256 کاراکتر وارد نمائید",


    // Price
    'PAYMENT PENDING': "پرداخت نشده",
    'PAID': "پرداخت شده",
    'ANSWERED': "پاسخ داده شده",
    'FREE': "رایگان",


    //---------------------------------------------------------------------------------------------------------------------
    // DOCTOR
    //---------------------------------------------------------------------------------------------------------------------
    'Your wallet': "کیف پول شما",
    'Tutorial': "آموزش استفاده",
    'Toman': "تومان",
    "RIAL": "ریال",
    "ShowDescriptionUser":"توضیحات نوبت شما از طرف متخصص",
    'Appointments': "قرار ملاقات ها",
    'Exercises': "تمرین ها",
    'Questions': "سوال و جواب",
    'Add your Answer': "پاسخ شما",
    'Your answer was sent successfully': "پاسخ شما با موفقیت ارسال شد",
    'Your question has been updated successfully': "سوال شما با موفقیت به روز شد",
    'Are you sure you want to delete this item?': "آیا از حذف این مورد مطمئن هستید؟",
    'Are you sure you want to delete this question?': "آیا از حذف این سوال مطمئن هستید؟",
    'Are you sure you want to delete this content?': "آیا از حذف این مطلب مطمئن هستید؟",
    'Your answer was delete successfully': "پاسخ شما با موفقیت حذف شد",
    'Your answer was edit successfully': "پاسخ شما با موفقیت ویرایش شد",
    'Your content was delete successfully': "مطلب شما با موفقیت حذف شد",
    'Your content was edit successfully': "مطلب شما با موفقیت ویرایش شد",

    'Edit profile': "ویرایش پروفایل",
    'Call information': "اطلاعات تماس",
    "Edit call information": "ویرایش اطلاعات تماس",
    'Clinic': "کلینیک",
    'My clinic': "کلینیک من",
    'Financial': "مالی",
    'Favorites': "نشان شده ها",
    'About us': "درباره ما",
    'Contact us': "تماس با ما",


    'Assign to clients': "فرستادن برای مراجعین",
    'Add to favorites': "افزودن به لیست مورد علاقه",
    'Add': "افزودن",
    'Remove from favorites': "حذف از لیست مورد علاقه",
    'Preview': "پیش نمایش",
    'Edit': "ویرایش",
    'submit':"ثبت",
    'Delete': "حذف",
    'Close': "بستن",

    'Latest posts': "آخرین مطالب",
    'Posts': "مطالب",
    'Videos': "ویدئو ها",
    'Podcasts': "پادکست ها",
    'Post': "مطلب",
    'Video': "ویدئو",
    'Podcast': "پادکست",
    'Follower': "دنبال کننده",
    'Followers': "دنبال کنندگان",
    'Answers': "پاسخ ها",
    'Question answered': "سوال پاسخ داده شده",
    'Call numbers': "شماره تماس",
    'In critical situations, such as any child abuse, spousal abuse or suicidal thoughts, call 123 Social Emergency': "در مواقع بحرانی مانند هرگونه کودک آزاری، همسرآزاری و یا افکار خودکشی بدون صبر با شماره 123 اورژانس اجتماعی تماس بگیرید",

    'Enter your answer in the box below': "پاسخ خود را در کادر زیر وارد نمائید",

    'You have no followers. The client must follow you to submit the exam': "شما دنبال کننده ای ندارید. برای ارسال آزمون کاربر باید شما را دنبال کند",
    'You have no followers. The client must follow you to submit the exercise': "شما دنبال کننده ای ندارید. برای ارسال تمرین کاربر باید شما را دنبال کند",
    'Search error': "خطا در جستجو",

    'Exam price': "قیمت آزمون",
    'Free': "رایگان",
    'The exam was removed from the favorites': "آزمون مورد نظر از علاقه مندی ها حذف شد",
    'The exam was added to the favorites': "آزمون مورد نظر به علاقه مندی ها اضافه شد",
    'Please add the exam to your favorites before sending it to client': "لطفا قبل از ارسال برای کاربر، آزمون را به لیست مورد علاقه های خود اضافه نمایید",
    'Exam assined to client': "آزمون به کاربر واگذار شد",
    'Please enter the price of the exam or check for free': "لطفا قیمت آزمون را وارد نمایید و یا تیک رایگان را بزنید",
    'No client selected': "کاربری انتخاب نشده است",
    
    'Exam preview': "پیش نمایش آزمون",
    'Questions Preview': "پیش نمایش سوالات",
    'Exam': "آزمون",
    'Exam details': "جزئیات آزمون",
    'Exercise': "تمرین",
    'Exam description': "توضیحات آزمون",
    'Number of questions:': "تعداد سوالات:",
    "YOU DON'T HAVE PERMISSION...": "...خطای عدم دسترسی",
    
    'Exercise time:': "زمان انجام تمرین:",
    'Please enter the exercise time correctly and more than zero': "لطفا زمان انجام تمرین را به درستی و بیشتر از صفر وارد نمایید",
    'The exercise was removed from the favorites': "تمرین مورد نظر از علاقه مندی ها حذف شد",
    'The exercise was added to the favorites': "تمرین مورد نظر به علاقه مندی ها اضافه شد",
    'Please add the exercise to your favorites before sending it to client': "لطفا قبل از ارسال برای کاربر، تمرین را به لیست مورد علاقه های خود اضافه نمایید",
    'Exercise assined to client': "تمرین به کاربر واگذار شد",
    'Are you sure you want to delete the exercise?': "آیا از حذف تمرین مطمئن هستید؟",
    'The exercise was successfully deleted': "تمرین مورد نظر با موفقیت حذف گردید",
    'Deleting exercise failed': "حذف تمرین ناموفق بود",

    'Create Exercise': "ساخت تمرین",
    'Edit Exercise': "ویرایش تمرین",

    'Exercise categories': "دسته بندی تمرین",
    'Exercise type': "نوع تمرین",
    'Exercise title': "عنوان تمرین",
    'Exercise description': "توضیحات تمرین",
    'Advanced': "پیشرفته",
    'Follow up text': "متن پیگیری",
    'days': "روز یکبار پیگیری کن",
    'Follow up once every': "هر",
    'If your client does not do his exercise, follow up': "اگر مراجعتون تمرینش رو انجام نداد پیگیری انجام بشه",
    'Follow up': "پیگیری",
    'Choose': "انتخاب کنید",
    'The exercise was made successfully': "تمرین با موفقیت ساخته شد",
    'The content was added successfully': "مطلب مورد نظر با موفقیت اضافه شد",

    'APPOINTMENT': "جلسه",
    'New content': "مطلب جدید",
    'Title': "عنوان",
    'Category': "دسته بندی",
    'Description': "توضیحات",
    'File selection': "انتخاب فایل",
    'Take a new photo': "گرفتن عکس جدید",
    'Select image from gallery': "انتخاب تصویر از گالری",
    'Select image': "انتخاب تصویر",
    'Change image': "تغییر تصویر",
    'Change video': "تغییر ویدئو",
    'Change': "تغییر",
    'Select video from gallery': "انتخاب ویدئو از گالری",
    'Select video': "انتخاب ویدئو",
    'Select image / video': "انتخاب تصویر / ویدئو",
    'Select image / file': "انتخاب تصویر / فایل",
    'Choice': "انتخاب",
    'Select file': "انتخاب فایل",

    'New request': "درخواست جدید",
    'Exam history': "تاریخچه آزمون",
    'Exercise history': "تاریخچه تمرین",

    'Done': "انجام شده",
    'Not done': "انجام نشده",
    'Done (View)': "انجام شده (مشاهده)",
    'View': "مشاهده",
    'Paid': "پرداخت شده",
    'Not paid': "پرداخت نشده",
    'Unknown': "نامعلوم",

    'Exam result': "نتیجه آزمون",
    'Exercise result': "نتیجه تمرین",

    'Date of prescription:': "تاریخ تجویز:",
    'Date of completion:': "تاریخ انجام:",
    'Year': "سال",
    'Age:': "سن:",
    'Exam scale': "مقیاس آزمون",
    'Exam guide': "راهنمای آزمون",

    'Last mood': "آخرین حالت روحی",

    "Fine": "خوبم",
    "Disappointed": "ناامیدم",
    "Worried": "نگرانم",
    "Stress": "استرس",
    "Sad": "غمگینم",
    "Nervous": "عصبی ام",
    "Slept well": "خوب خوابیدم",
    "Slept badly": "بد خوابیدم",

    "Call": "تماس",
    'Answered questions': "پرسش های پاسخ داده شده",
    'Save changes': "ذخیره تغییرات",
    
    'Clinic contact number': "شماره تماس کلینیک",
    'My own contact number': "شماره تماس خودم",
    'Phone Number': "تلفن",
    'Phone Number ( for Clinic )': "تلفن کلینیک ( این شماره در پروفایل نمایش داده می شود )",
    'Mobile Number ( Private )': "تلفن شخصی ( این شماره هیچ کجا نمایش داده نمی شود )",
    'Contact description': "توضیحات تماس",
    'Address': "آدرس",
    'Map': "نقشه",
    'Edit location': "ویرایش موقعیت",

    'Transactions': "تراکنش ها",
    'Settlements': "تسویه حساب",
    'Requests': "درخواست ها",
    'Settlement details': "جزئیات تسویه حساب",
    'Request for settlement': "درخواست تسویه حساب",
    'Amount': "نرخ",
    'Wallet': "کیف پول",
    'Counseling': "مشاوره",
    'IBAN': "شماره شبا",
    'Settlement requests': "درخواست های تسویه",
    'Unregistered': "ثبت نشده",

    'The cost of each one-hour session': "هزینه هر یک ساعت جلسه",
    'Payment rules': "قوانین پرداخت",
    'Payment before the session': "پرداخت قبل از شروع جلسه",
    'Payment after the session': "پرداخت بعد از اتمام جلسه",
    'Receive request SMS': "دریافت پیامک درخواست",
    'Consultation amount': "نرخ مشاوره",
    'Cost': "هزینه",
    'Next page': "صفحه بعد",
    'Pay': "پرداخت",
    
    'IBAN registration': "ثبت شماره شبا",
    'IBAN must be the same as the first and last name registered in the profile': "شماره شبا باید با نام و نام خانوادگی ثبت شده در پروفایل یکسان باشد",
    'First and last name': "نام و نام خانوادگی",
    'Bank': "بانک",
    'IBAN is invalid': "شماره شبا نامعتبر می باشد",

    'Sepah': "سپه",
    'Sarmayeh': "سرمایه",
    'Saderat': "صادرات",
    'Sanat-o-madan': "صنعت و معدن",
    'Karafarin': "کارآفرین",
    'Keshavarzi': "کشاورزی",
    'Markazi Bank': "بانک مرکزی",
    'Maskan': "مسکن",
    'Mellat': "ملت",
    'Ayandeh': "آینده",
    'Eghtesad-novin': "اقتصاد نوین",
    'Parsian': "پارسیان",
    'Pasargad': "پاسارگاد",
    'Post-bank': "پست بانک",
    'Tejarat': "تجارت",
    'Toseh': "توسعه",
    'Toseh-saderat': "توسعه صادرات",
    'Refah': "رفاه",
    'Saman': "سامان",
    'Melli': "ملی",
    'Ansar': "انصار",
    'Toseh-tavon': "توسعه تعاون",
    'Dey': "دی",
    'Ghavamin': "قوامین",
    'Gardeshgari': "گردشگری",
    'Hekmat-iranian': "حکمت ایرانیان",
    'Resalat': "رسالت",
    'Iran-zamin': "ایران زمین",
    'Sina': "سینا",
    'Shahr': "شهر",
    'Khavarmianeh': "خاورمیانه",
    'Mehr-iran': "مهر ایران",
    'Melal': "ملل",
    'Kosar': "کوثر",
    'Noor': "نور",
    
    'Error receiving wallet information': "خطا در دریافت اطلاعات کیف پول",
    'Your wallet balance is not enough. Please increase the inventory': "موجودی کیف پول شما کافی نمی باشد. لطفا موجودی را افزایش دهید",
    'Wallet balance is not enough': "موجودی کافی نمی باشد",
    'Increase wallet balance': "افزایش موجودی",
    'Error in paying the exam fee': "خطا در پرداخت هزینه آزمون",
    'Error in paying the consultant fee': "خطا در پرداخت هزینه مشاوره",
    'The exam fee has been paid successfully': "هزینه آزمون با موفقیت پرداخت شد",
    'The consultant fee has been paid successfully': "هزینه مشاوره با موفقیت پرداخت شد",
    'Success': "موفق",
    'Continue': "ادامه",
    
    'Your balance': "موجودی شما",
    'Wallet charge': "شارژ کیف پول",
    'Your payment amount...': "مبلغ پرداختی شما…",
    'Please enter the amount': "لطفا مبلغ را وارد کنید",
    'Please enter the consultant amount': "لطفا نرخ مشاوره را وارد کنید",
    'Please fill out the required information': "لطفا اطلاعات خواسته شده را وارد کنید",
    'The amount entered must be more than 1000 Toman': "مبلغ وارد شده باید بیشتر از 1000 تومان باشد",
    
    'Your wallet has been charged successfully': "کیف پول شما با موفقیت شارژ شد",
    'The wallet charge failed': "شارژ کیف پول ناموفق بود",
    'Payment failed. The payment has not been found': "تراکنش یافت نشد",
    'Payment failed. Payment has been duplicated': "درخواست تکراری می باشد",
    'Payment failed. Payment has been cancelled': "بیش از نیم ساعت از زمان اجرای تراکنش گذشته است",
    'Payment failed. The amount is not correct': "پرداخت ناموفق بود. مبلغ صحیح نیست",
    'Payment failed. The amount is less than the minimum payment': "پرداخت ناموفق بود. مبلغ کمتر از حداقل پرداختی است",
    'Payment failed. The payment system has a problem': "پرداخت ناموفق بود. سیستم پرداخت دچار مشکل شده است",
    'Payment failed. The response received from the bank is invalid': "پرداخت ناموفق بود. پاسخ دریافت شده از بانک نامعتبر است",
    'Payment failed. Invalid request': "پرداخت ناموفق بود. درخواست نامعتبر",
    'Payment failed. The bank has not responded. Please contact support': "پرداخت ناموفق بود. بانک پاسخگو نبوده است لطفا با پشتیبانی تماس بگیرید",
    'Payment failed. The refund was made correctly': "پرداخت ناموفق بود. بازگشت مبلغ به درستی انجام شد",
    'Payment failed. An error occurred in the refund operation': "پرداخت ناموفق بود. در عملیات بازگشت مبلغ خطا رخ داده است",
    'Payment failed. If money is deducted from your account, it will be returned to your account within 72 hours': "در صورت کسر وجه از حساب شما حداکثر ظرف مدت 72 ساعت به حساب بازگردانده میشود",
    
    'Consultant': "ویزیت",
    'Settled': "تسویه شده",
    'Awaiting settlement': "در انتظار تسویه",
    'Your account balance is not enough to request a settlement': "موجودی حساب شما برای درخواست تسویه حساب کافی نمی باشد",
    'Settlement request has been successfully sent': "درخواست تسویه حساب ارسال شد",
    'Unfortunately, sending the settlement request was unsuccessful. Please try again or contact support': "متاسفانه ارسال درخواست تسویه ناموفق بود. لطفا مجددا تلاش کنید و یا با پشتیبانی تماس بگیرید",
    
    'Pending': "در انتظار",
    'In progress': "در حال انجام",

    'Issue tracking': "شماره پیگیری",
    'Deposit date': "تاریخ واریز",
    'No description': "بدون توضیحات",
    
    'Operation report': "گزارش عملکرد",
    'Content': "محتوا",
    'Members': "اعضا",
    'Questions and answers': "پرسش و پاسخ",
    'Edit information': "ویرایش اطلاعات",
    'Add members': "افزودن اعضا",
    'Percent cooperation': "درصد همکاری",
    'Role': "نقش",
    'Licensing authority': "مرجع صادر کننده مجوز",
    'The organization of the psychological': "سازمان نظام روانشناسی",
    'Welfare organization': "سازمان بهزیستی",
    'About': "درباره",
    'ID': "شناسه",
    'Link': "آدرس اینترنتی",
    'Make an appointment': "نوبت گیری",
    'Schedule a patient': "نوبت دهی",
    'Score of clients feedback': "امتیاز بازخورد مراجعین",
    'Live streaming': "پخش زنده",
    'Emergency call': "تماس اضطراری",
    'Online': "آنلاین",
    'Telephone': "تلفنی",
    'Active': "فعال",
    'Inactive': "غیرفعال",
    'Meetings': "ملاقات ها",
    'Myself': "خودم",
    'Secretary': "منشی",
    
    'Awaiting confirmation': "در انتظار تایید",
    'Are you sure you want to unfollow the specialist?': "آیا از لغو دنبال کردن متخصص مطمئن هستید؟",
    
    'The new member has been successfully added to the clinic': "عضو جدید با موفقیت به کلینیک افزوده شد",
    'Error in registering information, please try again': "خطا در ثبت اطلاعات، لطفا مجددا سعی نمائید",
    
    'Clinic information': "مشخصات کلینیک",
    'Clinic name': "نام کلینک",
    'Membership type': "نوع عضویت",
    'Membership date': "تاریخ عضویت",
    'Clinic amount': "نرخ کلینیک",
    'Not determined': "تعیین نشده",

    'Appointment': "قرار ملاقات",
    'Search through questions, podcasts, content, specialists': "در سوالات ، پادکست ها ، مطالب ، متخصص ها جستجو کنید",
    'cases in specialists': "مورد در متخصصین",
    'cases in contents': "مورد در مطالب",
    'cases in videos': "مورد در ویدئو ها",
    'cases in podcasts': "مورد در پادکست ها",

    'Choose one': "یکی را انتخاب کنید",
    'Describe your mental state': "حالتو توصیف کن",
    'Other than the above, describe your mental state with a word or words': "غیر از موارد فوق حالت روحی خود را با کلمه یا کلماتی شرح دهید",
    'You need to write something': "باید چیزی بنویسی",
    'Are you sure about removing the mood?': "آیا از حذف حالت روحی مطمئن هستید؟",

    'Transaction is already verified': "به این تراکنش قبلاً رسیدگی شده است",
    'Order ID': "شناسه سفارش",
    'No image selected. Please select an image': "تصویری انتخاب نشده است. لطفا یک تصویر نمائید را انتخاب کنید",
    'No video selected. Please select an video': "ویدئویی انتخاب نشده است. لطفا یک ویدئو نمائید را انتخاب کنید",
    'No file selected. Please select an file': "فایلی انتخاب نشده است. لطفا یک فایل نمائید را انتخاب کنید",

    'Choose your education': "تحصیلات",
    'Choose your marriage status': "وضعیت متاهل",
    'Single': "مجرد",
    'Married': "متاهل",

    "DIPLOMA": "دیپلم",
    "TECHNIQUE": "کاردانی",
    "BACHELOR": "لیسانس",
    "MA": "فوق لیسانس",
    "RESEARCH": "دکترا",
    "OTHER": "سایر",
    
    "NEXT_MEETING": "ملاقات بعدی امروز",
    "NO_MEETING_SCHEDULED": "متاسفانه شما فعلا مراجعی ندارید. برای اضافه کردن وقت ملاقات جدید اینجا بزنید",
    "SCHEDULE": "وقت دهی",
    "APPOINTMENTS": "نوبت ها",
    "NO_APPOINTMENTS_REGISTERED": "نوبتی ثبت نشده است",
    "NO_APPOINTMENTS_RESERVED": "نوبتی رزرو نشده است",
    "ADD_APPOINTMENT": "افزودن نوبت",
    "TIME_IS_RESERVED_BEFORE": "نوبت قبلا رزرو شده است",
    "TIME_IS_RESERVED_SUCCESSFULLY": "نوبت با موفقیت رزرو شد",
    "APPOINTMENT_DATE": "تاریخ جلسه",
    "APPOINTMENT_START_TIME": "ساعت شروع",
    "APPOINTMENT_END_TIME": "ساعت خاتمه",
    "APPOINTMENT_PRICE": "نرخ مشاوره",
    "DESCRIPTION":'توضیحات',
    "DESCRIPTION-DOCTOR":"توضیحات نوبت خود را وارد کنید ",
    "APPOINTMENT_TYPE": "نوع جلسه",
    "APPOINTMENT_TIME_AVAILABLE": "آزاد",
    "APPOINTMENT_TIME_REGISTERED": "ثبت شده",
    "APPOINTMENT_TIME_PASSED": "گذشته",
    "ARE_YOU_SURE_TO_DELETE_TIME":"آیا از حذف بازه زمانی از {from} تا {to} مطمئن هستید؟",
    "CHANGE_CONSULTANT_AMOUNT_HERE": "نرخ مشاوره را از اینجا تغییر دهید",

    "ONLINE": "آنلاین",
    "ON_SITE": "حضوری",
    "BOTH": "هر دو",
    "SAVE": "ذخیره",
    "ARE_YOU_SURE": "مطمئن هستید؟",

    "APPOINTMENTS_LIST": "نوبت گرفتن",
    "USER_APPOINTMENT_PER_HOUR": "هزینه هر یک ساعت جلسه",
    "USER_APPOINTMENT_TYPE_SELECTION": "نوع نوبت گیری",
    "USER_APPOINTMENT_NEXT": "بعدی",
    "USER_APPOINTMENT_PREVIOUS": "قبلی",
    "USER_APPOINTMENT_CANCEL": "لغو",
    "CANCEL_RESERVED_APPOINTMENT": "لغو جلسه",
    "I Understand": "متوجه شدم",
    "CLOSEST_USER_APPOINTMENT": "سریعترین زمان مشاوره",
    "NO_USER_APPOINTMENT_FOR_DATE": "نوبتی برای این تاریخ تعریف نشده است",
    "USER_APPOINTMENT_REQUEST_PARAGRAPH": "با ثبت درخواست در اولین فرصت منشی با شما تماس خواهد گرفت.",
    "USER_APPOINTMENT_REQUEST_REGISTER": "ثبت درخواست",
    "Please contact the support team for cancellation": "برای لغو این نوبت با پشتیبانی تماس بگیرید",
    "USER-APPOINTMENT-MORE-INFO": "جزییات",
    "USER-APPOINTMENT-PAID": "پرداخت شده",
    "USER-APPOINTMENT-NOT-PAID": "پرداخت نشده",

  },
  en: {}
};
