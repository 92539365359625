const getters = {
  getLoadingBar (state) {
    return state.loadingBar
  },

  isLoggedIn: (state) => !!state.token,
  token: (state) => state.token,
  firebaseToken: (state) => state.firebaseToken,
  authStatus: (state) => state.status,
  authenticated: (state) => state.authenticated,

  notificationActive: (state) => state.notificationActive,
  threeDotsActive: (state) => state.threeDotsActive,
  threeDotsMenu: (state) => state.threeDotsMenu,
  helloActive: (state) => state.helloActive,
  backActive: (state) => state.backActive,
  toolbarTitle: (state) => state.toolbarTitle,
  toolbarTitleActive: (state) => state.toolbarTitleActive,
  fullName: (state) => state.fullName,
  notificationCount: (state) => state.notificationCount,
  paymentStatus: (state) => state.paymentStatus,
  paymentAmount: (state) => state.paymentAmount,
  mobileNumber: (state) => state.mobileNumber,
  clientType: (state) => state.clientType,
  doctorId: (state) => state.doctorId,
  clientId: (state) => state.clientId,
  profileFilled: (state) => state.profileFilled,
  clinicId: (state) => state.clinicId,

  locale: (state) => state.locale,
};
export default getters;
