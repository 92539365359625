var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isInstallPromptAvailable
    ? _c(
        "v-banner",
        {
          class: _vm.bannerClasses,
          attrs: {
            dark: "",
            app: "",
            color: "primary",
            outlined: "",
            rounded: "",
            sticky: "",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "secondary" },
                        on: { click: _vm.dismissPrompt },
                      },
                      [_vm._v(_vm._s(_vm.$t("Dismiss")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "secondary" },
                        on: { click: _vm.installPWA },
                      },
                      [_vm._v(_vm._s(_vm.$t("Install")))]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            2356509942
          ),
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "Get our free app. It won't take up space on your phone!"
                )
              ) +
              " "
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }