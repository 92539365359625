const state = {
  //EXAM
  loadingBar: false,
  //LOADING
  refCount: 0,
  isLoading: false,
  locale: 'fa',

  //AUTH
  status: "",
  authenticated: "",
  token: "",
  firebaseToken: "",

  //USER
  mobileNumber: "",
  clientType: "",
  doctorId: "",
  profileFilled: "",
  clinicId: "",
  clientId:"",

  //APP ITEMS
  notificationActive: true,
  threeDotsActive: false,
  threeDotsMenu: [],
  helloActive: true,
  backActive: false,
  toolbarTitleActive: false,
  toolbarTitle: "",
  fullName: "",
  notificationCount: 0,
  paymentAmount: null,
  paymentStatus: null
};

export default state;
