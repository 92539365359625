// axios
import axios from 'axios'
import store from "@/store/store.js"

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
  // You can add your headers here
});

// instance.defaults.timeout = 10000
// instance.defaults.timeoutErrorMessage='timeout'

instance.interceptors.request.use(
  (config) => {
    store.commit("httpLoading", true);
    return config;
  },
  (error) => {
    store.commit("httpLoading", false);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    store.commit("httpLoading", false);
    return response;
  },
  (error) => {
    const { response } = error;
    // const { config, response } = error
    // const originalRequest = config
    if (response && response.status === 401 || response.status === 403) {
      store.dispatch("logout");
    }

    store.commit("httpLoading", false);
    return Promise.reject(error);
  }
);

export default instance;